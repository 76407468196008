import React from 'react'
import { HighChartsUtils } from 'modules/reports/components/charts/card-chart/inner/HighChartsUtils'
import { CardHighChartCP } from 'modules/reports/components/charts/card-chart/CardHighChartCP'
import { LocationCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/LocationCorrelationChartResponseDTO'

interface ICPProps {
    data: LocationCorrelationChartResponseDTO
}

/**
 */
export function ChartLocationCorrelationCP(props: ICPProps): JSX.Element {

    const chartFormattedData: [number, number][] = []
    props.data.serie.dataArray.forEach((item) => {
        chartFormattedData.push([+item.x, +item.y])
    })

    return (
        <CardHighChartCP
            chartType={'scatter'}
            buttonsToHide={['chartType']}
            chartOptions={{
                chart: HighChartsUtils.getChart('scatter'),
                title: HighChartsUtils.getTitle(`Gráfico de correlação de ${props.data.serie.xAxisLabel} e ${props.data.serie.yAxisLabel}`),
                series: [
                    {
                        name: 'Correlação',
                        data: chartFormattedData,
                    } as any,
                    {
                        name: 'Linha de Tendência',
                        type: 'line',
                        data: props.data.linearRegression.trendLine.map((trendLine) => [+trendLine.x, +trendLine.y]),
                    } as any,
                ],
                xAxis: {
                    title: {
                        text: props.data.serie.xAxisLabel,
                    }
                },
                yAxis: {
                    title: {
                        text: props.data.serie.yAxisLabel,
                    }
                },
                subtitle: {
                    text: `${props.data.linearRegression.linearRegressionFormula}<br>R² = ${props.data.linearRegression.rSquare}`, // Texto no rodapé
                    align: 'center', // Alinha o texto ao centro (pode ser 'left' ou 'right')
                    style: {
                        fontSize: '12px', // Tamanho da fonte
                        color: '#646464', // Cor do texto
                    }
                },
            }}
        />
    )
}
