import React from 'react'
import { HighChartsUtils } from 'modules/reports/components/charts/card-chart/inner/HighChartsUtils'
import { CardHighChartCP } from 'modules/reports/components/charts/card-chart/CardHighChartCP'
import { IQAChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/IQAChartResponseDTO'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { IQAEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/IQAEnum'

interface ICPProps {
    data: IQAChartResponseDTO
}

/**
 */
export function ChartWaterQualityCP(props: ICPProps): JSX.Element {

    return (
        <CardHighChartCP
            chartType={'line'}
            buttonsToHide={['chartType', 'linearScale']}
            chartOptions={{
                chart: HighChartsUtils.getChart('scatter'),
                title: HighChartsUtils.getTitle('Gráfico do Indice da Qualidade da Água'),
                series:
                    [
                        {
                            type: 'area',
                            name: props.data.constrolSeries.iqaSerieP100.xAxisLabel,
                            data: props.data.constrolSeries.iqaSerieP100.dataArray.map((item) => [new Date(item.x).getTime(), +item.y]),
                            color: ThemeProjectCommon.qualityWaterColors[IQAEnum.EXCELENT],
                            marker: { enabled: false }
                        },
                        {
                            type: 'area',
                            name: props.data.constrolSeries.iqaSerieP90.xAxisLabel,
                            data: props.data.constrolSeries.iqaSerieP90.dataArray.map((item) => [new Date(item.x).getTime(), +item.y]),
                            color: ThemeProjectCommon.qualityWaterColors[IQAEnum.GOOD],
                            marker: { enabled: false }
                        },
                        {
                            type: 'area',
                            name: props.data.constrolSeries.iqaSerieP70.xAxisLabel,
                            data: props.data.constrolSeries.iqaSerieP70.dataArray.map((item) => [new Date(item.x).getTime(), +item.y]),
                            color: ThemeProjectCommon.qualityWaterColors[IQAEnum.MEDIUM],
                            marker: { enabled: false }
                        },
                        {
                            type: 'area',
                            name: props.data.constrolSeries.iqaSerieP50.xAxisLabel,
                            data: props.data.constrolSeries.iqaSerieP50.dataArray.map((item) => [new Date(item.x).getTime(), +item.y]),
                            color: ThemeProjectCommon.qualityWaterColors[IQAEnum.BAD],
                            marker: { enabled: false }
                        },
                        {
                            type: 'area',
                            name: props.data.constrolSeries.iqaSerieP25.xAxisLabel,
                            data: props.data.constrolSeries.iqaSerieP25.dataArray.map((item) => [new Date(item.x).getTime(), +item.y]),
                            color: ThemeProjectCommon.qualityWaterColors[IQAEnum.VERY_BAD],
                            marker: { enabled: false }
                        },
                        ...props.data.iqaSeries.map((serie) => ({
                            type: 'spline',
                            name: serie.xAxisLabel,
                            data: serie.dataArray.map((item) => [new Date(item.x).getTime(), +item.y])
                        })) as any,
                    ]
                ,
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    max: 100,
                    min: 0,
                    title: {
                        text: 'Intervalos'
                    }
                },
                exporting: {
                    enabled: true,
                }
            }}
        />
    )
}
